.imsouane-surf-guide-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.imsouane-surf-guide-top-container {
  width: 100%;
  height: 400px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-size: cover;
  background-image: linear-gradient(180deg, rgba(189, 195, 199, 0) 74.00%,rgb(255, 255, 255) 100.00%),url("https://images.unsplash.com/photo-1533813857885-4bfd57de64e4?ixid=M3w5MTMyMXwwfDF8c2VhcmNofDN8fGltc291YW5lfGVufDB8fHx8MTY5NTM5OTg3M3ww&ixlib=rb-4.0.3&w=1500");
  background-position: bottom;
}
.imsouane-surf-guide-navbar {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-doubleunit);
  max-width: auto;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  background-image: linear-gradient(0deg, rgba(189, 195, 199, 0) 0.00%,rgb(68, 49, 30) 100.00%);
}
.imsouane-surf-guide-navlink {
  display: contents;
}
.imsouane-surf-guide-logo {
  color: var(--dl-color-primary-sunsetorange) #FFFFFF;
  text-decoration: none;
}
.imsouane-surf-guide-text {
  color: var(--dl-color-gray-white);
  font-style: normal;
  font-weight: 300;
}
.imsouane-surf-guide-text001 {
  color: var(--dl-color-gray-white);
}
.imsouane-surf-guide-text002 {
  color: var(--dl-color-primary-sunsetorange);
}
.imsouane-surf-guide-right-side {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.imsouane-surf-guide-links-container {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.imsouane-surf-guide-home {
  color: var(--dl-color-gray-white);
  margin-right: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.imsouane-surf-guide-taxi {
  color: var(--dl-color-gray-white);
  margin-right: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.imsouane-surf-guide-taxi1 {
  color: var(--dl-color-gray-white);
  margin-right: var(--dl-space-space-doubleunit);
  text-decoration: none;
}
.imsouane-surf-guide-about {
  color: var(--dl-color-gray-white);
  margin-right: var(--dl-space-space-doubleunit);
}
.imsouane-surf-guide-burger-menu {
  display: none;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.imsouane-surf-guide-burger-menu1 {
  width: 24px;
  height: 24px;
}
.imsouane-surf-guide-mobile-menu {
  top: 0px;
  flex: 0 0 auto;
  left: 0px;
  width: 100%;
  height: 100%;
  display: none;
  padding: var(--dl-space-space-doubleunit);
  z-index: 100;
  position: absolute;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.imsouane-surf-guide-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.imsouane-surf-guide-top {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-doubleunit);
  flex-direction: row;
  justify-content: space-between;
}
.imsouane-surf-guide-close-menu {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.imsouane-surf-guide-icon03 {
  width: 24px;
  height: 24px;
}
.imsouane-surf-guide-right-side1 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.imsouane-surf-guide-links-container1 {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  flex-direction: column;
  justify-content: space-between;
}
.imsouane-surf-guide-navlink1 {
  margin-bottom: 8px;
  text-decoration: none;
}
.imsouane-surf-guide-navlink2 {
  margin-bottom: 8px;
  text-decoration: none;
}
.imsouane-surf-guide-text003 {
  margin-bottom: 8px;
  text-decoration: none;
}
.imsouane-surf-guide-text004 {
  text-decoration: none;
}
.imsouane-surf-guide-follow-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: space-between;
}
.imsouane-surf-guide-text005 {
  padding-bottom: var(--dl-space-space-halfunit);
}
.imsouane-surf-guide-icons-container {
  width: 100px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: space-between;
}
.imsouane-surf-guide-link {
  display: contents;
}
.imsouane-surf-guide-icon05 {
  width: 24px;
  height: 24px;
}
.imsouane-surf-guide-link1 {
  display: contents;
}
.imsouane-surf-guide-icon07 {
  width: 24px;
  height: 24px;
}
.imsouane-surf-guide-link2 {
  display: contents;
}
.imsouane-surf-guide-icon09 {
  width: 24px;
  height: 24px;
}
.imsouane-surf-guide-hero {
  flex: 1;
  width: 100%;
  display: flex;
  max-width: auto;
  align-items: center;
  padding-top: var(--dl-space-space-tripleunit);
  padding-left: var(--dl-space-space-doubleunit);
  padding-right: var(--dl-space-space-doubleunit);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-tripleunit);
  justify-content: space-between;
}
.imsouane-surf-guide-container1 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  align-self: flex-end;
  align-items: flex-start;
  padding-left: var(--dl-space-space-quadruple);
  flex-direction: column;
  justify-content: flex-end;
}
.imsouane-surf-guide-text006 {
  color: #383838;
  align-self: flex-start;
}
.imsouane-surf-guide-container2 {
  flex: 0 0 auto;
  display: flex;
  align-self: stretch;
  align-items: flex-start;
  padding-left: 25%;
  padding-right: 25%;
  flex-direction: column;
}
.imsouane-surf-guide-text007 {
  width: 80%;
  align-self: flex-start;
}
.imsouane-surf-guide-text013 {
  font-size: 17px;
  font-weight: 700;
}
.imsouane-surf-guide-text014 {
  font-size: 17px;
  font-weight: 700;
}
.imsouane-surf-guide-text017 {
  line-height: 1.5;
}
.imsouane-surf-guide-text018 {
  line-height: 1.5;
}
.imsouane-surf-guide-text019 {
  line-height: 1.5;
}
.imsouane-surf-guide-text020 {
  line-height: 1.5;
}
.imsouane-surf-guide-text021 {
  line-height: 1.5;
}
.imsouane-surf-guide-text022 {
  line-height: 1.5;
}
.imsouane-surf-guide-text023 {
  line-height: 1.5;
}
.imsouane-surf-guide-text024 {
  line-height: 1.5;
}
.imsouane-surf-guide-text025 {
  line-height: 1.5;
}
.imsouane-surf-guide-text026 {
  line-height: 1.5;
}
.imsouane-surf-guide-text027 {
  line-height: 1.5;
}
.imsouane-surf-guide-text028 {
  line-height: 1.5;
}
.imsouane-surf-guide-text029 {
  line-height: 1.5;
}
.imsouane-surf-guide-text030 {
  line-height: 1.5;
}
.imsouane-surf-guide-text031 {
  line-height: 1.5;
}
.imsouane-surf-guide-text032 {
  line-height: 1.5;
}
.imsouane-surf-guide-text033 {
  line-height: 1.5;
}
.imsouane-surf-guide-text034 {
  line-height: 1.5;
}
.imsouane-surf-guide-text035 {
  line-height: 1.5;
}
.imsouane-surf-guide-text036 {
  line-height: 1.5;
}
.imsouane-surf-guide-text037 {
  line-height: 1.5;
}
.imsouane-surf-guide-image {
  width: 80%;
  margin-top: var(--dl-space-space-doubleunit);
  object-fit: cover;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.imsouane-surf-guide-text040 {
  width: 80%;
  align-self: flex-start;
}
.imsouane-surf-guide-text041 {
  font-weight: 600;
}
.imsouane-surf-guide-text042 {
  font-weight: 600;
}
.imsouane-surf-guide-text047 {
  font-style: normal;
  font-weight: 600;
}
.imsouane-surf-guide-text048 {
  font-style: normal;
  font-weight: 600;
}
.imsouane-surf-guide-text053 {
  font-style: normal;
  font-weight: 600;
}
.imsouane-surf-guide-text054 {
  font-style: normal;
  font-weight: 600;
}
.imsouane-surf-guide-feature-card {
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: flex-start;
  transition: 0.3s;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}

.imsouane-surf-guide-container3 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
}
.imsouane-surf-guide-container4 {
  width: 20%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.imsouane-surf-guide-icon11 {
  fill: var(--dl-color-primary-sunsetorange);
  width: var(--dl-size-size-tripleunit);
  height: var(--dl-size-size-tripleunit);
}
.imsouane-surf-guide-text059 {
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-bottom: var(--dl-space-space-unit);
}
.imsouane-surf-guide-text060 {
  color: var(--dl-color-gray-700);
  width: 80%;
  text-align: left;
}
.imsouane-surf-guide-navlink3 {
  transition: 0.3s;
  border-color: var(--dl-color-primary-sunsetorange);
  text-decoration: none;
}
.imsouane-surf-guide-navlink3:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-primary-sunsetorange);
}
.imsouane-surf-guide-text061 {
  width: 80%;
  align-self: flex-start;
}
.imsouane-surf-guide-text062 {
  font-style: normal;
  font-weight: 600;
}
.imsouane-surf-guide-text063 {
  font-style: normal;
  font-weight: 600;
}
.imsouane-surf-guide-feature-card1 {
  width: 100%;
  height: auto;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-self: flex-start;
  transition: 0.3s;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}

.imsouane-surf-guide-container5 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
}
.imsouane-surf-guide-container6 {
  width: 20%;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.imsouane-surf-guide-image1 {
  width: var(--dl-size-size-tripleunit);
  height: var(--dl-size-size-tripleunit);
  object-fit: cover;
  margin-right: var(--dl-space-space-doubleunit);
}
.imsouane-surf-guide-text071 {
  font-style: normal;
  margin-top: var(--dl-space-space-unit);
  text-align: center;
  font-weight: 500;
  margin-right: var(--dl-space-space-halfunit);
  margin-bottom: var(--dl-space-space-unit);
}
.imsouane-surf-guide-text072 {
  color: var(--dl-color-gray-700);
  width: 80%;
  text-align: left;
}
.imsouane-surf-guide-navlink4 {
  transition: 0.3s;
  border-color: var(--dl-color-primary-sunsetorange);
  text-decoration: none;
}
.imsouane-surf-guide-navlink4:hover {
  color: var(--dl-color-gray-white);
  background-color: var(--dl-color-primary-sunsetorange);
}
.imsouane-surf-guide-text073 {
  width: 80%;
  align-self: flex-start;
}
.imsouane-surf-guide-text075 {
  font-style: normal;
  font-weight: 600;
}
.imsouane-surf-guide-text076 {
  font-style: normal;
  font-weight: 600;
}
.imsouane-surf-guide-image2 {
  width: 80%;
  margin-top: var(--dl-space-space-doubleunit);
  object-fit: cover;
  margin-bottom: var(--dl-space-space-doubleunit);
}
.imsouane-surf-guide-text080 {
  width: 80%;
  align-self: flex-start;
}
.imsouane-surf-guide-text081 {
  font-style: normal;
  font-weight: 500;
}
.imsouane-surf-guide-text082 {
  font-style: normal;
  font-weight: 500;
}
.imsouane-surf-guide-text083 {
  font-style: normal;
  font-weight: 500;
}
.imsouane-surf-guide-text088 {
  font-style: normal;
  font-weight: 500;
}
.imsouane-surf-guide-text089 {
  font-style: normal;
  font-weight: 500;
}
.imsouane-surf-guide-text094 {
  font-style: normal;
  font-weight: 500;
}
.imsouane-surf-guide-text095 {
  font-style: normal;
  font-weight: 500;
}
.imsouane-surf-guide-text100 {
  font-style: normal;
  font-weight: 500;
}
.imsouane-surf-guide-text101 {
  font-style: normal;
  font-weight: 500;
}
.imsouane-surf-guide-text106 {
  font-style: normal;
  font-weight: 600;
}
.imsouane-surf-guide-text107 {
  font-style: normal;
  font-weight: 600;
}
.imsouane-surf-guide-text112 {
  font-style: normal;
  font-weight: 500;
}
.imsouane-surf-guide-text113 {
  font-style: normal;
  font-weight: 500;
}
.imsouane-surf-guide-text118 {
  font-style: normal;
  font-weight: 500;
}
.imsouane-surf-guide-text119 {
  font-style: normal;
  font-weight: 500;
}
.imsouane-surf-guide-text124 {
  font-style: normal;
  font-weight: 500;
}
.imsouane-surf-guide-text125 {
  font-style: normal;
  font-weight: 500;
}
.imsouane-surf-guide-text130 {
  font-style: normal;
  font-weight: 600;
}
.imsouane-surf-guide-text131 {
  font-style: normal;
  font-weight: 600;
}
.imsouane-surf-guide-text136 {
  font-weight: 500;
}
.imsouane-surf-guide-text137 {
  font-weight: 500;
}
.imsouane-surf-guide-text142 {
  font-style: normal;
  font-weight: 500;
}
.imsouane-surf-guide-text143 {
  font-style: normal;
  font-weight: 500;
}
.imsouane-surf-guide-text148 {
  font-style: normal;
  font-weight: 500;
}
.imsouane-surf-guide-text149 {
  font-style: normal;
  font-weight: 500;
}
.imsouane-surf-guide-text154 {
  font-weight: 600;
}
.imsouane-surf-guide-text155 {
  font-weight: 600;
}
.imsouane-surf-guide-text160 {
  font-weight: 600;
}
.imsouane-surf-guide-text161 {
  font-weight: 600;
}
.imsouane-surf-guide-text166 {
  font-style: normal;
  font-weight: 600;
}
.imsouane-surf-guide-text167 {
  font-style: normal;
  font-weight: 600;
}
.imsouane-surf-guide-text172 {
  font-style: normal;
  font-weight: 600;
}
.imsouane-surf-guide-text173 {
  font-style: normal;
  font-weight: 600;
}
.imsouane-surf-guide-text182 {
  font-style: italic;
  font-weight: 100;
}
@media(max-width: 991px) {
  .imsouane-surf-guide-hero {
    padding-left: var(--dl-space-space-tripleunit);
    padding-right: var(--dl-space-space-tripleunit);
  }
}
@media(max-width: 767px) {
  .imsouane-surf-guide-right-side {
    display: none;
  }
  .imsouane-surf-guide-burger-menu {
    display: flex;
  }
  .imsouane-surf-guide-hero {
    justify-content: center;
  }
}
@media(max-width: 479px) {
  .imsouane-surf-guide-mobile-menu {
    display: none;
  }
  .imsouane-surf-guide-text004 {
    text-decoration: none;
  }
  .imsouane-surf-guide-text006 {
    width: 479px;
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
  }
  .imsouane-surf-guide-container2 {
    padding-left: var(--dl-space-space-doubleunit);
    padding-right: var(--dl-space-space-doubleunit);
  }
  .imsouane-surf-guide-text007 {
    width: 100%;
    text-align: justify;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .imsouane-surf-guide-text040 {
    width: 100%;
    text-align: justify;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .imsouane-surf-guide-text061 {
    width: 100%;
    text-align: justify;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .imsouane-surf-guide-text073 {
    width: 100%;
    text-align: justify;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .imsouane-surf-guide-text080 {
    width: 100%;
    text-align: justify;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
}
